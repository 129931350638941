html,
body {
  height: 100%;
  max-width: 100%;
  /* overflow-x: hidden !important; */
  /* overscroll-behavior-y:none ; */
  overflow-x: hidden !important;
}

body {
  overscroll-behavior-y: none;
  background-color: #eeeeee !important;
}

.container {
  width: 100%;
  padding: 0;
}

.full-page.login-page {
  background-image: url(../template_assests/images/photos/login1.jpg);
  /* background-color:rgb(51 102 255 / 23%); */
  background-repeat: no-repeat;
  background-size: cover;
  /*height: 100vh; */
  /* Full height */
  height: 100vh;
  background-position: bottom;
  /*background-position:center;*/
}

.full-page:before {
  background-color: transparent !important;
}

/*   .main-container{
  height: 100% !important;
}  */
.wrapper {
  background-color: #eeeeee !important;
  height: auto !important;
  position: inherit !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.main-panel {
  width: 100% !important;
}

/* app-customers{
  display: block;
} */

.main-panel>.content {
  margin-top: 0px !important;
  padding: 0 15px !important;
}

/* @media (min-width: 768px) and (max-width: 991px) {
  .navbar-header {
    float: none !important;
  }

 
}
@media (min-width: 991px) {
  .navbar-right {
    margin-top: 15px !important;
  }
} */
@media (max-width: 767px) {
  .nav_right {
    display: none !important;
  }

  .d-xs-flex,
  .d-sx-flex {
    display: flex;
  }

  .d-xs-block,
  .d-sm-block {
    display: block;
  }

  .flex-xs-row,
  .flex-sm-row,
  .flex-md-row {
    flex-direction: row;
  }

  .flex-xs-column,
  .flex-sm-column {
    flex-direction: column;
  }



}

@media (min-width: 768px) {
  .nav_right {
    margin: 10px 15px !important;
  }
}

@media (max-width: 991px) {

  .nav-open .off-canvas-sidebar,
  .nav-open .sidebar {
    transform: translate3d(-462px, 0, 0) !important;
  }
}

.navbar-default .navbar-toggle {
  display: none !important;
}

.navbar-header {
  float: left !important;
}

.fixed_footer {
  position: fixed;
  height: 50px;
  bottom: 0;
  width: 100%;
}

#loader {
  position: fixed;
  z-index: 99999;
  /* left: 0px;
  right: 0px;
  bottom: 0px; */
  top: 0px;
  height: 100%;
  width: 100vw;
  background: rgb(0, 0, 0, 0.5);
  text-align: center;
  padding-top: 15%;
  display: none;
}

#overlay {
  position: fixed;
  z-index: 9999;
  /* left: 0px;
  right: 0px;
  bottom: 0px; */
  top: 0px;
  height: 100%;
  width: 100vw;
  background: rgb(0, 0, 0, 0.5);
  text-align: center;
  padding-top: 15%;
  display: none;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.vertical-center {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.btn:focus {
  outline: none !important;
}

.form-group {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.form-control:focus {
  box-shadow: 0px 0px 1px 1px #3366ff;
}


/* changes made */
.form-group .form-control {
  padding: 6px 6px !important;
  appearance: auto;

}

button:focus {
  border: 1px solid black !important;
}

button:focus-visible {
  outline: 1px solid black;
}

/* a:focus{
  border: 1px solid black !important;
}
a:focus-visible{
  outline: 1px solid black;
} */
/* .form-group .form-control:focus{
  color: #424e79;
  background-color: #fff;
  border-color: blue;
  outline: 0;
  box-shadow: none;
}


.form-group .form-control:valid{
  color: #424e79;
  background-color: #fff;
  border-color: red;
  outline: 0;
  box-shadow: none;
} */

/* changes end */
.actionBar {
  width: 100%;
  padding: 10px 5px;
  text-align: right;
  margin-top: 10px;
}

.actionBar .buttonDisabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}

.actionBar a {
  margin: 0 3px;
}

.tile-stats {
  position: relative;
  display: block;
  margin: 20px 30px;
  border: 1px solid #e4e4e4;
  -webkit-border-radius: 5px;
  overflow: hidden;
  padding: 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 5px;
  -moz-background-clip: padding;
  border-radius: 5px;
  background-clip: padding-box;
  background: #fff;
  transition: all 300ms ease-in-out;
}

.site_title {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  font-size: 22px;
  width: 100%;
  margin-left: 0 !important;
  line-height: 59px;
  display: block;
  margin: 0;
  padding-left: 10px;
  color: #fff;
}

.site_title:hover,
.site_title:focus {
  text-decoration: none;
}

.shortcuts.tooltiptext {
  /* Added By MAK - refered from bootstrap tooltip */
  position: absolute;
  z-index: 1070;
  /* display: block; */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;

  line-break: auto;

  font-weight: bold;
  padding: 2px 5px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.sidebar_control,
.sidebar_control .left_col.scroll-view .navbar.nav_title {
  width: 0;
  transition: 0.5s;
  overflow-x: hidden;
  /* width: 250px; */
  position: fixed;
  /*	height: 100%;   changed from 500px by MAK */
  height: 100%;
  /* sujata modified*/
  z-index: 9999;
}

.sidebar_control {
  position: absolute;
  width: 0;
  transition: 0.5s;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #ffffff;
  height: 100%;
  /* sujata modified*/
  z-index: 9999;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
}

.sidebar_control .nav li>a,
.off-canvas-sidebar .nav li>a {
  margin: 10px 10px 0;
  border-radius: 3px;
  color: #222222;
  transition: all 150ms ease-in;
}

.sidebar_control .nav p {
  margin: 0;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.sidebar_control .nav i {
  font-size: 24px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  color: #555555;
}

.sidebar_control .nav .caret {
  margin-top: 13px;
  position: absolute;
  right: 18px;
}

.sidebar_control .nav {
  margin-top: 15px;
}

.sidebar_control .nav [data-toggle="collapse"]~div ul>li>a {
  padding-left: 60px;
  color: #6f6f6f;
}

.sidebar .nav li:first-child>a {
  margin: 0 10px;
}

.sidebar_control .logo {
  padding: 13px 15px;
}

.sidebar_control .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  color: #222222;
  text-align: center;
  white-space: nowrap;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
}

.sidebar_control .logo:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: rgba(180, 180, 180, 0.3);
}

.main_menu_side {
  padding: 0 0 30px 0;
}

.main_menu span.fa {
  float: right;
  text-align: center;
  margin-top: 5px;
  font-size: 10px;
  min-width: inherit;
  color: #c4cfda;
}

.main_menu .fa {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main_menu .label {
  line-height: 11px;
  margin-top: 4px;
}

.moduleHistory {
  margin-bottom: 80px;
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #f7f7f7;
  z-index: 11;
  opacity: 0.2;
}

.nav-sm .main_container .top_nav {
  display: block;
  margin-left: 100px;
  z-index: 2;
}

.nav_menu {
  float: left;
  width: 100%;
  position: relative;
  height: 60px;
}

.navbar-icon {
  float: left;
  padding: 0 15px !important;
}

.navbar-icon button.btn-just-icon {
  padding: 10px 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.09);
}

.navbar-icon button.btn-just-icon:focus {
  outline: none !important;
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.pro-fab {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 800;
}

/* .table>thead>tr>th{
  border:none !important;
  font-size: 1.1em !important;
} */
.borderless td,
.borderless th {
  border: none !important;
  padding: 8px 12px 8px 0 !important;
}

.btn.btn-drpdwn {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ccc !important;
}

.btn-group.open>.dropdown-toggle.btn,
.btn-group.open>.dropdown-toggle.btn.btn-drpdwn,
.btn-group-vertical.open>.dropdown-toggle.btn,
.btn-group-vertical.open>.dropdown-toggle.btn.btn-drpdwn {
  background-color: #fff !important;
  color: #000 !important;
}

.form-group .help-block {
  display: block;
}

.textcolor {
  color: #263871;
}

.scrollsearchresults {
  min-height: auto !important;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: scroll;
}

.scrollsearchresults::-webkit-scrollbar {
  width: 12px;
}

table::-webkit-scrollbar {
  width: 12px;
}

.scrollsearchresults::-webkit-scrollbar:horizontal {
  height: 12px;
}

.lightmode_formly {
  background-color: white !important;
}

@media screen and (max-width: 600px) {
  /* .mobile table thead {
    display: none;
  } 
   .mobile table tbody td {
    display: grid;
    padding: 0.3rem;
    width: 100%;
  }

  .mobile table tbody tr td:first-child {
    background: #3366ff;
    color: #fff;
  }
  .mobile table tbody tr td:first-child a {
    color: #fff;
  }

  .mobile table tbody tr td:first-child:before {
    color:white;
  }

  .mobile table tbody td:before {
    content: attr(data-th);
    font-weight: bold;
    display: inline-block;
    width: 18rem;
  }

  .mobile table tr th:last-child,
  .mobile table tr td:last-child {
    max-width: 100% !important;
    min-width: 100px !important;
    width: 100% !important;
  }

  .mobile table tbody td .form-control {
    float: none !important;
    width: 50%;
    display: inline;
  } */


  .mobile table {
    overflow: scroll !important;
    max-width: 600px !important;
    min-width: 200px !important;
  }

  .mobile table {
    color: black !important;
    background-color: white !important;
  }
}

.app {
  background: #eef5ff;
  border-radius: 10px;
  /* padding: 8px !important; */
}

.frequentApps img {
  width: 75px;
  height: 75px;
}

.frequentApps .app {
  padding: 8px 10px !important;
  min-height: 11.1em;
}

.calender .card-content {
  min-height: 353px;
}

.max-w {
  max-width: 50%;
}

.dashboard .card {
  border-radius: 10px;
}

.dashboard .card-title {
  color: #737373;
}

.dashboard .about-text {
  color: #888888;
  line-height: 35px;
  font-weight: normal;
}

.arrow-bg {
  background: #c4c4c4;
  height: 20px;
  border-radius: 5px;
}

.arrow1 {
  margin-right: 10px;
}

.app-title {
  color: #616161;
  font-weight: normal;
}

.calender-wrapper {
  max-width: 1100px;
}

.container-calendar {
  background: #ffffff;
  padding: 4px 15px 15px 15px;
  max-width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.dark-mode .container-calendar {
  background: #25274a;
  padding: 4px 15px 15px 15px;
  max-width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #3366ff;
  color: #fff;
  border: 1px solid #3366ff;
  border-radius: 4px;
  padding: 5px 10px;
}

.table-calendar {
  border-collapse: collapse;
  width: 100%;
}

.table-calendar td,
.table-calendar th {
  padding: 5px;
  border: 1px solid #e2e2e2;
  text-align: center;
  vertical-align: top;
}

.dark-mode .table-calendar td,
.dark-mode .table-calendar th {
  padding: 5px;
  border: 1px solid #151635;
  text-align: center;
  vertical-align: top;
}

.date-picker.selected {
  font-weight: bold;
  outline: 1px dashed #00bcd4;
}

.date-picker.selected span {
  border-bottom: 2px solid currentColor;
}

/* sunday */
.date-picker:nth-child(1) {
  color: red;
}

/* friday */
.date-picker:nth-child(6) {
  color: green;
}

#monthAndYear {
  text-align: center;
  margin-top: 0;
}

.button-container-calendar {
  position: relative;
  margin-bottom: 1em;
  overflow: hidden;
  clear: both;
}

#previous {
  float: left;
}

#next {
  float: right;
}

.footer-container-calendar {
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}

/* .footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
} */

.daily-tasks .badge {
  text-align: right !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.daily-tasks .btn {
  top: 0;
  right: 0;
}

.task-container {
  max-height: 20em;
  min-height: 20em;
  overflow-y: auto;
  margin-top: 10px;
}

/* .badge-danger {
  color: #b94a48;
} */
/* .badge-danger:hover {
  background-color: #953b39;
} */
.badge-warning {
  background-color: #f89406;
}

.badge-warning:hover {
  color: #c67605;
}

/* .badge-success {
  color: #468847;
} */
/* .badge-success:hover {
  background-color: #356635;
} */
.badge-info {
  color: #3a87ad;
}

/* .badge-info:hover {
  color: #2d6987;
} */
.badge-inverse {
  color: #333333;
}

/* .badge-inverse:hover {
  color: #1a1a1a;
} */
.d-flex,
.d-md-flex {
  display: flex;
}

.d-block,
.d-md-block {
  display: block;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-items-baseline {
  align-items: baseline;
}

.justify-content-between {
  justify-content: space-between;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  max-width: 100% !important;
}



/* width */
.task-container::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

/* Track */
.task-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.task-container::-webkit-scrollbar-thumb {
  background-color: #2196F3;
  border-radius: 10px;
}

/* Handle on hover */
.task-container::-webkit-scrollbar-thumb:hover {
  background-color: #2196F3;
}

/* changes V2 */
.dropdown-item:hover,
.dropdown-item:focus {
  color: #3366ff !important;
  text-decoration: none !important;
  background-color: #f3f5fb !important;
  cursor: pointer;
}

.size-logo {
  height: 100%;
}

#logo_size-60 {
  width: 45px;
  margin-right: 20px;
}

#scroll_container {
  height: 205px;
}

#header_sticky {
  position: sticky;
  top: 0;
  background-color: white;
}

.scroll {
  overflow-y: scroll;
}

.scale_photo {
  height: 36px !important;
  transform: scale(1.5);
}

.scale_mobile_photo {
  margin: auto !important;
  height: 45px !important;
  transform: scale(1.15);

}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

/* custom utility classes */
.padd-15 {
  /* 1 rem = 16px */
  padding: 0.935rem;
}

.fontSize-15 {
  font-size: 0.95rem;
}

.fontSize-20 {
  font-size: 1.3rem;
}

.fontSize-25 {
  font-size: 1.5625rem;
}

.marginLeft-12 {
  margin-left: 0.75rem;
}

.rounded_corners {
  border-radius: 12px;
  overflow: hidden;
}

.padd-0 {
  padding: 0 !important;
}

.col_primary {
  color: #263871;
}

.col_primary_opacity {
  color: #26377188;
}

.scroll::-webkit-scrollbar {
  width: 10px;
}

.width_112 {
  width: 112px;
}

#overlayMainComponent {
  position: fixed;
  z-index: 999;
  top: 0px;
  height: 100%;
  width: 100vw;
  background: rgb(0, 0, 0, .1);
  text-align: center;
  padding-top: 15%;
  display: none;
}

.highlightActiveLink {
  font-weight: 500;
  color: #3366ff !important;
}

.box_Shadow {
  box-shadow: 0 0 18px 1px #00000042;
}

.marginTop {
  margin-top: 72px;
}

.cliptext {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 765px) {
  .w-md-20 {
    width: 20% !important;
  }

}

@media screen and (min-width: 770px) and (max-width:991px) {
  .navbar-collapse .profile-dropdown .show.dropdown-menu {
    left: 0px !important;
    /* width: 300px !important; */
  }


}


.mat-list-base .mat-list-item .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mat-list-base .mat-list-item .mat-list-item-ripple {
  display: none;
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  height: fit-content !important;
}

@media only screen and (max-width: 650px) {
  .mat-list-base .mat-list-item .mat-list-item-content {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start;
  }
}

.disable-div {
  pointer-events: none;
  opacity: 0.4;
}

.display-none {
  display: none !important;
}

.cdk-overlay-container {
  z-index: 11000 !important;
}

/* custom utility classes */

/* changes V2 */

.remove_isymbol .form-control.is-invalid {
  background-image: none;
}

.showcaltoleft {
  top: 265.375px !important;
  left: 304px !important;
  right: auto !important;
}



@media (max-width: 425px) {
  .mobileP0 {
    padding: 0px !important;
  }
}