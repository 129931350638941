.s_l_section {
    height: fit-content;
    background-image: url(../../assets/img/bg_blue_white.jpg);
    background-repeat: repeat;
    background-size: cover;
    height: 100vh;
    min-height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hold_page_contnts_div {
    width: 90%;
    max-width: 1300px;
    height: fit-content;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    border-radius: 0.9rem;
    border: 0.5px solid #086AB1;
    background: #FFF;
    box-shadow: -3px -3px 4px -1px rgba(0, 0, 0, 0.25);
}

.top_heading {
    padding: 20px 0;
    color: #FFF !important;
    text-align: left;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: 'Roboto', sans-serif;
}

.my-h {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.hold_form_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.w-45-fix {
    width: 70% !important;
}

.my-login {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_one {
    color: #303030;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0rem;
}

.hold_image_text {
    margin-bottom: 3rem;
}

.text_two {
    color: #323030;
    ;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text_three {
    color: #086AB1;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 0.80rem !important;
}

.bottom_heading {
    margin-top: 0rem;
    color: #323030 !important;
    text-align: left;
    font-size: 17px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal !important;
    font-family: 'Roboto', sans-serif !important;
}


.app_log {
    width: 100%;
    height: 100px;
    /* height: 13vh; */
    text-align: center;
    margin-bottom: 20px;
}

.app_log img {
    height: 100%;
}


@media only screen and (max-width: 800px) {

    .top_heading,
    .text_one,
    .text_two,
    .text_three,
    .bottom_heading {
        display: none;
        /* Hide the elements */
    }

    .s_l_section {
        height: fit-content;
        min-height: 100dvh;

        background-image: none;
        background-color: white;
    }

    .hold_page_contnts_div {
        width: 100%;
        min-height: 100vh;
        border-radius: 0;
        border: none;
        background: #FFF;
        box-shadow: none;
        justify-content: center;
        padding: 0px;
    }

    .app_log_ {
        display: block;
        padding-top: 1.95rem;
        margin-top: 20vh;
    }

    .hide_element {
        display: none;
    }

    .w-45-fix {
        width: 100% !important;
    }

    .my-login {
        display: flex;
        align-items: center;
        justify-content: center;

    }
}

@media only screen and (max-width: 500px) {

    /* Hide the top heading, additional texts, or any elements */
    .top_heading,
    .text_one,
    .text_two,
    .text_three,
    .bottom_heading {
        display: none;
        /* Hide the elements */
    }


    /* .hold_page_contnts_div {
        width: 100%;
        min-height: 100vh;
        border-radius: 0;
        border: none;
        background: #FFF;
        box-shadow: none;
    } */

    /* Ensure the logo and form are responsive */
    .app_log img {
        padding-top: 1.00rem;
        margin-top: 1vh;
        margin-bottom: 20vh;
    }

    .card {
        max-width: 100%;
        /* Full width for mobile */
        margin: 0 auto;
        padding: 10px;
    }

    .my-login {
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-top: 0vh !important; */
        margin-bottom: 37vh !important;
    }

}

.p-3 {
    padding-top: 0.65rem !important;

}